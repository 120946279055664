import { PreferenceKeys, PreferencesService } from 'processes/Preferences';
import { ReactComponent as AppStore } from 'shared/assets/images/app_store_badge.svg';
import { ReactComponent as GooglePlay } from 'shared/assets/images/google_play_badge.svg';
import { useTranslation } from 'react-i18next';

import styles from './Footer.module.scss';

function Footer(props: { preferencesService: PreferencesService }) {
  const { t } = useTranslation();

  const footerInfo = props.preferencesService
    .get(PreferenceKeys.FOOTER_INFO)
    .replace('{YEAR}', new Date().getFullYear().toString());

  return (
    <footer className={styles.footer}>
      <div className={styles.copyContainer}>
        <p className={styles.copyright}>{footerInfo}</p>
        {t('requisites') && (<p className={styles.requisites}>{t('requisites')}</p>)}
      </div>
      <ul className={styles.linkContainer}>
        {props.preferencesService.getNullable(PreferenceKeys.POLICY_LINK) && (
          <li className={styles.linkItem}>
            <a
              href={props.preferencesService.getNullable(PreferenceKeys.POLICY_LINK)}
              target="_blank"
              rel="noreferrer"
            >
              {t('footer.privacy_policy')}
            </a>
          </li>
        )}
        {props.preferencesService.getNullable(PreferenceKeys.OFERTA_LINK) && (
          <li className={styles.linkItem}>
            <a
              href={props.preferencesService.getNullable(PreferenceKeys.OFERTA_LINK)}
              target="_blank"
              rel="noreferrer"
            >
              {t('footer.public_offer')}
            </a>
          </li>
        )}
      </ul>
      {( props.preferencesService.getNullable(PreferenceKeys.APPSTORE_LINK) ||
        props.preferencesService.getNullable(PreferenceKeys.GOOGLEPLAY_LINK) ) && (
        <LinkAppContainer
          appStoreLink={props.preferencesService.getNullable(PreferenceKeys.APPSTORE_LINK)}
          googlePlayLink={props.preferencesService.getNullable(PreferenceKeys.GOOGLEPLAY_LINK)}
        />
      )}
    </footer>
  );
}

export default Footer;

function LinkAppContainer(props: { appStoreLink?: string; googlePlayLink?: string }) {
  return (
    <ul className={styles.linkAppContainer}>
      {props.appStoreLink && (
        <li className={styles.linkItem}>
          <a aria-label="AppStore" href={props.appStoreLink} target="_blank" rel="noreferrer">
            <AppStore />
          </a>
        </li>
      )}

      {props.googlePlayLink && (
        <li className={styles.linkItem}>
          <a aria-label="Google Play" href={props.googlePlayLink} target="_blank" rel="noreferrer">
            <GooglePlay />
          </a>
        </li>
      )}
    </ul>
  );
}
